import ChannelType from '@/domain/collector/card/channel/channelType';
import DeviceStatus from '@/domain/collector/deviceStatus';
import { SceneTreeNode } from '@/domain/scene';
import APIRequest from '../apiRequest';
import JSONWebRequest from '../jsonWebRequest';
import GetSceneTreeResponse, { ISceneTreeNode } from './getSceneTreeResponse';
import PtCollectorId from '@/domain/collector/collectorId';
import ModbusDeviceId from '@/domain/modbusDevice/modbusDeviceId';
import ModbusChannelId from '@/domain/modbusDevice/channel/channelId';
import PtChannelId from '@/domain/collector/card/channel/channelId';
import { wrapNumberEntityId } from '@/domain/entityId';

export default class GetSceneTreeRequest extends APIRequest<GetSceneTreeResponse> {
  constructor(
    private params: {
      isAdmin: boolean;
      hasCollectorNode: boolean;
    }
  ) {
    super();
  }

  protected createWebRequest() {
    const req = new JSONWebRequest<GetSceneTreeResponse>(
      this.params.isAdmin
        ? '/Project/GetTreeList'
        : '/Project/GetClientTreeList'
    );
    if (this.params.isAdmin && this.params.hasCollectorNode) {
      req.addParam('isMac', true);
    }
    return req;
  }

  processResponse() {
    this.response.data ??= [];
    convert(this.response.data, [], null);
    function convert(nodes: any[], path: any, parent: any) {
      nodes.forEach((node) => {
        const apiNode = node as ISceneTreeNode;
        apiNode.parent = parent;
        apiNode.workpointId = node.workPointId;
        if (apiNode.nodeType == SceneTreeNode.Scene) {
          apiNode.sceneId = apiNode.detectContentId;
        } else if (apiNode.nodeType == SceneTreeNode.PtCollector) {
          apiNode.deviceStatus = node.isOnLine
            ? node.deviceStatus
            : DeviceStatus.Offline;
          Object.defineProperty(node, 'isOnline', {
            get() {
              return ![DeviceStatus.Offline, DeviceStatus.NetworkFail].includes(
                this.deviceStatus
              );
            },
          });
          delete node.isOnLine;
          if (!node.name) {
            node.name = node.sn || node.mac;
          }
          apiNode.id = apiNode.deviceId = new PtCollectorId({
            dbId: node.deviceId,
            mac: node.mac,
            sn: node.sn,
          });
          apiNode.deviceMac = node.mac;
          apiNode.pushIntervel = node.sendTime;
        } else if (apiNode.nodeType == SceneTreeNode.PtChannel) {
          apiNode.channelType =
            (
              {
                1: ChannelType.Vibration,
                2: ChannelType.Noise,
              } as Record<number, ChannelType>
            )[node.channelType] ?? ChannelType.None;
          apiNode.id = apiNode.channelId = new PtChannelId({
            dbId: node.id,
          });
          apiNode.showFeatureDataItems = node.showFeatureDataItems
            ? node.showFeatureDataItems.split(',')
            : [];
        } else if (apiNode.nodeType == SceneTreeNode.ModbusDevice) {
          apiNode.children.forEach((child: any, index: number) => {
            child.channelNo = index;
          });
          apiNode.channels = node.children;
          apiNode.id = apiNode.deviceId = new ModbusDeviceId({
            dbId: node.deviceId,
          });
        } else if (apiNode.nodeType == SceneTreeNode.ModbusChannel) {
          apiNode.id = apiNode.channelId = new ModbusChannelId({
            dbId: node.id,
          });
        } else {
          apiNode.id = wrapNumberEntityId(node.id);
        }

        apiNode.path =
          apiNode.nodeType == SceneTreeNode.Project
            ? []
            : path.concat([
                { id: +node.id, name: apiNode.name, node: apiNode },
              ]);
        apiNode.key = makeKey(apiNode.nodeType, node.id);

        if (apiNode.children) {
          convert(apiNode.children, apiNode.path, node);
          if (apiNode.nodeType == SceneTreeNode.ModbusDevice) {
            apiNode.children = [];
          }
        }
      });
    }

    function makeKey(nodeType: number, id: number) {
      return `${typeToCode[nodeType]}_${id}`;
    }
  }
}

const typeToCode = {
  [SceneTreeNode.Project]: 'project',
  [SceneTreeNode.Scene]: 'scene',
  [SceneTreeNode.Workpoint]: 'workpoint',
  [SceneTreeNode.PtCollector]: 'pt_collector',
  [SceneTreeNode.PtChannel]: 'pt_channel',
  [SceneTreeNode.ModbusDevice]: 'modbus_device',
  [SceneTreeNode.ModbusChannel]: 'modbus_channel',
} as Record<number, string>;
