import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { appShortTitle } from '@/appInfo';
import store from '@/store';
import { constantRoutes } from './routes';
import qs from 'qs';
import { getApiAccess } from '@/online/api/apiAccess';
import { useAuthenticate } from '@/application/auth';

Vue.use(VueRouter);

const router = createRouter();

function createRouter() {
  return new VueRouter({
    mode: 'hash',
    routes: constantRoutes,
  });
}

export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher;
}

router.beforeEach((to, from, next) => {
  changeTitle(to);

  if (to.name == 'login') {
    next();
    return;
  }

  let isWaitLoad = false;
  const api = getApiAccess();
  if (api.hasLogin) {
    if (!api.localUser) {
      const loading = Vue.prototype.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)',
        customClass: 'app-loading',
      });
      const { loadUser } = useAuthenticate();
      loadUser().then(() => {
        loading.close();
      });
      isWaitLoad = true;
    }
  } else {
    next(
      to.path != '/'
        ? `/login?redirect=${to.path}?${qs.stringify(to.query)}`
        : '/login'
    );
    return;
  }

  if (isWaitLoad) {
    setTimeout(() => {
      changeActiveMenu(to);
    }, 100);
  } else {
    changeActiveMenu(to);
  }

  next();
});

function changeTitle(route: Route) {
  let title = appShortTitle;
  if (route.meta?.title) {
    title += ' - ' + route.meta.title;
  }
  document.title = title;
}

function changeActiveMenu(route: Route) {
  store.dispatch('menu/change', route);
}

export default router;
