export default interface EntityId {
  equals(other: any): boolean;
  valueOf(): number | string | undefined;
  toString(): string;
}

export function wrapNumberEntityId(id: number) {
  return {
    equals(other: any) {
      if (typeof other != 'number') return false;
      return other == id;
    },
    toString() {
      return id + '';
    },
    valueOf() {
      return id;
    },
  } as EntityId;
}
