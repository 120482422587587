const pad2 = (d: number) => (d > 9 ? d : `0${d}`);

export function formatTime(d: Date | number, ms = false) {
  if (typeof d == 'number') {
    d = new Date(d);
  }
  return (
    [d.getFullYear(), pad2(d.getMonth() + 1), pad2(d.getDate())].join('-') +
    ' ' +
    [
      pad2(d.getHours()),
      pad2(d.getMinutes()),
      pad2(d.getSeconds()),
      ms && pad2(d.getMilliseconds()),
    ]
      .filter(Boolean)
      .join(':')
  );
}

// 处理时间字符串秒
export function formatTimeSeconds(time: string, seconds: string) {
  const dateTime = time.substring(0, 16);
  const newTime = `${dateTime}:${seconds}`;
  return newTime;
}
