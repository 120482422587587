export default interface APIResponse<T = any> {
  code: number;
  data: T;
  msg: string;
  isOk: boolean;
}

export function convertRawResponse(rawRes: any): APIResponse {
  const res = {} as APIResponse;
  res.msg = rawRes.errorMsg || rawRes.dicMsg;
  res.code = rawRes.code;
  res.data = rawRes.data;
  res.isOk = rawRes.code == 0;
  return res;
}

export function failResult() {
  return {
    code: 1,
    isOk: false,
    data: null,
    msg: '',
  } as APIResponse;
}

export function successResult() {
  return {
    code: 0,
    isOk: true,
    data: null,
    msg: '',
  } as APIResponse;
}
