import EntityId from '@/domain/entityId';

export default class PtCollectorId implements EntityId {
  private _dbId: number;
  get dbId() {
    return this._dbId;
  }

  private _mac: string;
  get mac() {
    return this._mac;
  }

  private _sn: string;
  get sn() {
    return this._sn;
  }

  constructor({
    dbId,
    mac,
    sn,
  }: Partial<{ dbId: number; mac: string; sn: string }>) {
    this._dbId = dbId!;
    this._mac = mac!;
    this._sn = sn!;
  }

  equals(other: any) {
    if (!(other instanceof PtCollectorId)) {
      return false;
    }
    if (typeof this.dbId == 'number' && typeof other.dbId == 'number') {
      return this.dbId == other.dbId;
    }
    if (typeof this.sn == 'string' && typeof other.sn == 'string') {
      return this.sn == other.sn;
    }
    if (typeof this.mac == 'string' && typeof other.mac == 'string') {
      return this.mac == other.mac;
    }
    return false;
  }

  valueOf() {
    if (this._dbId) return this._dbId;
    if (this._sn) return this._sn;
    if (this._mac) return this._mac;
  }

  toString() {
    if (this._dbId) return this._dbId + '';
    if (this._sn) return this._sn;
    if (this._mac) return this._mac;
    return '';
  }
}
