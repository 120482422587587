import APIAccess, { getApiAccess } from './apiAccess';
import WebRequest from './webRequest';
import JSONWebRequest from './jsonWebRequest';
import signals from 'signals';
import APIResponse, { failResult } from './requests/responses/apiResponse';

export default abstract class APIRequest<T = APIResponse> {
  protected target: string;
  protected webRequest: WebRequest;
  protected response: T;

  success = new signals.Signal() as signals.Signal<T>;
  failure = new signals.Signal();

  perform(api: APIAccess = getApiAccess()) {
    this.webRequest = this.createWebRequest();
    this.webRequest.addHeader('TokenValue', api.token ?? '');
    this.webRequest.failed.add(this.fail, this);
    return this.webRequest?.perform<T>().then(async (result) => {
      // todo: http.ts处理移到此处
      if (!result) {
        return failResult() as unknown as T;
      }
      this.response = result;
      this.triggerSuccess(this.response);
      await this.processResponse();
      return this.response;
    });
  }

  protected createWebRequest(): WebRequest {
    return new JSONWebRequest<T>(this.target);
  }

  protected processResponse(): void {
    // abstract
  }

  private fail(e: any) {
    this.triggerFailure(e);
  }

  private triggerSuccess(result: T) {
    this.success.dispatch(result);
  }

  private triggerFailure(result: T) {
    this.failure.dispatch(result);
  }
}
