import APIRequest from '../../apiRequest';
import APIUser from '../responses/apiUser';
import APIResponse from '../responses/apiResponse';
import { RoleId } from '@/domain/role';

export default class GetUserRequest extends APIRequest<APIResponse<APIUser>> {
  constructor(private id: number) {
    super();
  }

  protected createWebRequest() {
    const req = super.createWebRequest();
    req.addParam('id', this.id);
    return req;
  }

  processResponse() {
    const rawRes = this.response.data as any;
    const user = new APIUser();
    user.id = rawRes.id;
    user.username = rawRes.userName;
    user.nickname = rawRes.nickName;
    user.roleIds =
      rawRes.roles.length == 0 ? [RoleId.SuperAdmin] : rawRes.roles ?? [];
    user.isEnable = rawRes.isEnable;
    user.email = rawRes.email;
    user.phone = rawRes.mobile;
    user.createTime = rawRes.createTime;
    this.response.data = user;
  }

  protected target = '/SysUser/Get';
}
