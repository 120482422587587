import { asyncRoutes, constantRoutes } from '@/router/routes';
import { RouteConfig } from 'vue-router';

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes: RouteConfig[], roles: string[]) {
  const res: RouteConfig[] = [];

  routes.forEach((route) => {
    const hasPermission =
      route.meta?.roles === undefined ||
      route.meta?.roles === '*' ||
      roles?.some((role: string) => route.meta?.roles.includes(role));
    if (hasPermission) {
      const tmp = { ...route };
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

export default {
  namespaced: true,
  state: {
    roles: [],
    routes: [],
  },
  mutations: {
    SET_ROUTES: (state: any, routes: RouteConfig[]) => {
      state.routes = constantRoutes.concat(routes);
    },
  },
  actions: {
    setRoles({ state }: any, roles: string[]) {
      state.roles = roles;
    },
    generateRoutes({ commit }: any, roles: string[]) {
      return new Promise((resolve) => {
        const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
        commit('SET_ROUTES', accessedRoutes);
        resolve(accessedRoutes);
      });
    },
  },
};
