/** 采集器采集状态 */
enum DeviceStatus {
  /** 采集中（并在线） */
  Collecting = 1,
  /** 已停止（并在线） */
  Stopping = 2,
  Stoped = 2,

  /** 故障 */
  Fault = 3,
  /** 网络故障 */
  NetworkFail = 4,
  /** 离线 */
  Offline = 5,
  /** 在线（是一个特定的状态） */
  Online = 6,
  /** 开始采集中（并在线）（客户端自定义状态）  */
  CollectingStatePending = 10001,
  /** 停止采集中（并在线）（客户端自定义状态）  */
  StopedStatePending = 10002,
}

export default DeviceStatus;

export function getDeviceStatusText(status: DeviceStatus) {
  return {
    [DeviceStatus.Online]: '设备在线',
    [DeviceStatus.Offline]: '设备离线',
    [DeviceStatus.Collecting]: '采集中',
    [DeviceStatus.Stopping]: '未采集',
    [DeviceStatus.Fault]: '故障',
    [DeviceStatus.NetworkFail]: '网络失败',
    [DeviceStatus.CollectingStatePending]: '开始采集中',
    [DeviceStatus.StopedStatePending]: '停止采集中',
  }[status];
}
