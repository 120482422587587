export default interface Role {
  id: number;
  name: string;
  isEnable: boolean;
  remark: string;
}

export enum RoleId {
  SuperAdmin = 1,
  Manager = 2,
  User = 3,
}

export function roleIdToCode(roleId: number) {
  return {
    [RoleId.SuperAdmin]: 'superAdmin',
    [RoleId.Manager]: 'manager',
    [RoleId.User]: 'user',
  }[roleId];
}
