import axios from 'axios';
import qs from 'qs';
import { Message, MessageBox } from 'element-ui';
import Vue from 'vue';
import { getApiAccess } from './apiAccess';
import { convertRawResponse } from './requests/responses/apiResponse';
import { useAuthenticate } from '@/application/auth';

const http = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

http.interceptors.request.use(
  (config) => {
    config.headers!['TokenValue'] = getApiAccess().token ?? '';
    if (
      config.headers!['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
      if (config.data) {
        config.data = qs.stringify(config.data);
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let showLoginConfirmMessageBox = false;

http.interceptors.response.use(
  (response: any) => {
    const data = response.data;
    if (data instanceof Blob) {
      return response;
    }
    const result = convertRawResponse(data);

    if (
      data.code !== undefined &&
      !result.isOk &&
      result.msg &&
      response.config.url != '/SysUser/LogOut'
    ) {
      Message({
        message: result.msg,
        type: 'error',
      });
    }
    return result;
  },
  (error) => {
    if (error.response?.status === 401) {
      getApiAccess().offline();
      if (!showLoginConfirmMessageBox) {
        showLoginConfirmMessageBox = true;
        const confirm = MessageBox.confirm(
          '你未登陆或在之前已登出, 点击取消停留在此页, 或者重新登陆',
          '提示',
          {
            confirmButtonText: '重新登陆',
            cancelButtonText: '取消',
            type: 'warning',
          }
        );
        confirm
          .then(() => {
            const { logout } = useAuthenticate();
            logout();
          })
          .finally(() => {
            showLoginConfirmMessageBox = false;
          });
      }
    } else if (error.response?.status === 404) {
      // pass
    } else {
      Message({
        message: error.message,
        type: 'error',
      });
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$http = http;

export default http;
