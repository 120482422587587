import APIRequest from '../apiRequest';
import { HttpMethod } from '../webRequest';

export default class LogoutRequest extends APIRequest {
  protected createWebRequest() {
    const req = super.createWebRequest();
    req.method = HttpMethod.Post;
    return req;
  }

  protected target = '/SysUser/LogOut';
}
