import { UserSysAccount } from '@/domain/user';
import LoginRequest from './requests/loginRequest';
import LogoutRequest from './requests/logoutRequest';
import APIUser from './requests/responses/apiUser';
import GetUserRequest from './requests/user/getUserRequest';

export enum APIState {
  /** 未登录 */
  Offline,
  /** 登录失败 */
  Failing,
  /** (重新)登录中 */
  Connecting,
  /** 在线  */
  Online,
}

export default class APIAccess {
  // todo: 可观察状态
  private _state = APIState.Offline;
  get state() {
    return this._state;
  }

  // todo: 将http.ts代码移动到此
  get token(): string | null {
    return sessionStorage.getItem('token');
  }

  private _localUser: APIUser | null = null;

  get localUser(): APIUser | null {
    return this._localUser;
  }

  get localUserId() {
    return +sessionStorage.getItem('userId')! ?? null;
  }

  get hasLogin() {
    return this.token && this.localUserId;
  }

  async auth(account: UserSysAccount) {
    this._state = APIState.Connecting;
    const result = await new LoginRequest(account).perform();
    if (result.isOk) {
      const user = result.data.user;
      this.storeToken(result.data.token);
      this.setLocalUser(user);
      this._state = APIState.Online;
      return user;
    } else {
      this._state = APIState.Failing;
      return null;
    }
  }

  async loadUser() {
    if (!(this.localUserId && this.token)) {
      return Promise.reject();
    }

    const result = await new GetUserRequest(this.localUserId).perform();
    if (result.isOk) {
      const user = result.data;
      this.setLocalUser(user);
      this._state = APIState.Online;
      return user;
    } else {
      this.offline();
      return Promise.reject();
    }
  }

  async logout() {
    if (this.state == APIState.Online) {
      await new LogoutRequest().perform(this);
      this.offline();
    } else {
      this.offline();
      return Promise.resolve();
    }
  }

  offline() {
    this._localUser = null;
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('token');
    this._state = APIState.Offline;
  }

  private storeToken(token: string | null) {
    sessionStorage.setItem('token', token ?? '');
  }

  private setLocalUser(user: APIUser) {
    this._localUser = user;
    sessionStorage.setItem('userId', user.id + '');
  }
}

let apiAccess: APIAccess | null = null;
export function getApiAccess() {
  if (!apiAccess) {
    apiAccess = new APIAccess();
  }
  return apiAccess;
}
