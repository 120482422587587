import { RoleId } from '@/domain/role';
import { User, UserProfile, UserRoleAware } from '@/domain/user';

export default class APIUser implements User, UserProfile, UserRoleAware {
  id: number;
  username: string;
  nickname: string;
  roleIds: RoleId[];
  isEnable: boolean;
  email: string;
  phone: string;
  remark: string;
  createTime: string;

  isRole(roleId: RoleId) {
    return this.roleIds.includes(roleId);
  }

  get hasRole() {
    return this.roleIds.length > 0;
  }

  get isRootUser() {
    return this.username == 'admin';
  }

  get isDemoUser() {
    return this.username == 'shiyantaitest';
  }
}
