import APIRequest from '../apiRequest';
import APIUser from './responses/apiUser';
import { HttpMethod } from '../webRequest';
import LoginResponse, { Menu } from './loginResponse';
import { RoleId } from '@/domain/role';
import { UserSysAccount } from '@/domain/user';

export default class LoginRequest extends APIRequest<LoginResponse> {
  constructor(private account: UserSysAccount) {
    super();
  }

  protected createWebRequest() {
    const req = super.createWebRequest();
    req.method = HttpMethod.Post;
    req.timeout = 5000;
    req.addParam('userName', this.account.username);
    req.addParam('pwd', this.account.password);
    return req;
  }

  protected processResponse() {
    if (!this.response.isOk) return;
    const rawRes = this.response.data as unknown as {
      userId: number;
      nickName: string;
      roleIds: number[];
      isAdmin: boolean;
      token: string;
      data: Menu[];
    };
    const user = new APIUser();
    user.id = rawRes.userId;
    user.username = this.account.username;
    user.nickname = rawRes.nickName;
    user.roleIds =
      rawRes.roleIds.length == 0 ? [RoleId.SuperAdmin] : rawRes.roleIds ?? [];
    user.isEnable = true;
    this.response.data = {
      user,
      token: rawRes.token,
    };
  }

  protected target = '/SysUser/Login';
}
