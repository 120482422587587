
import Vue from 'vue';
import Navigation from './Navigation/index.vue';

export default Vue.extend({
  name: 'HomeLayout',
  components: {
    Navigation,
  },
});
