
import { useAuthenticate } from '@/application/auth';
import store from '@/store';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const { logout } = useAuthenticate();

    function onCommand(cmd: string) {
      switch (cmd) {
        case 'logout':
          logout();
          break;
      }
    }

    return {
      user: computed(() => (store.state as any).user.user),
      onCommand,
    };
  },
});
