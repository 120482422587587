/** 通道类型 */
enum ChannelType {
  None,
  /** 振动 */
  Vibration,
  /** 声压 */
  Noise,
}

export default ChannelType;
