import { getApiAccess } from '@/online/api/apiAccess';
import router, { resetRouter } from '@/router';
import store from '@/store';
import { RoleId, roleIdToCode } from '@/domain/role';
import { UserRoleAware } from '@/domain/user';
import GetSceneTreeRequest from '@/online/api/requests/getSceneTreeRequest';
import { findNode } from '@/utils/tree';
import { ISceneTreeNode } from '@/online/api/requests/getSceneTreeResponse';

export interface LoginParams {
  username: string;
  password: string;
  rememberMe: boolean;
}

export function useAuthenticate() {
  const apiAccess = getApiAccess();

  async function login(params: LoginParams) {
    const user = await apiAccess.auth({
      username: params.username,
      password: params.password,
      isEnable: true,
    });
    if (!user) {
      return null;
    }

    localStorage.setItem('username', params.username);
    if (params.rememberMe) {
      localStorage.setItem('password', params.password);
    }

    store.dispatch('user/save', user);
    await loadMenusAndRoutes(user);
    return user;
  }

  async function logout() {
    router.push('/login');
    await getApiAccess().logout();
    store.dispatch('user/save', null);
  }

  async function loadUser() {
    const user = await apiAccess.loadUser();
    store.dispatch('user/save', user);
    return await loadMenusAndRoutes(user);
  }

  return {
    login,
    logout,
    loadUser,
  };
}

async function loadMenusAndRoutes(user: UserRoleAware & { id: number }) {
  let roleCodes = user.roleIds.map((roleId) => roleIdToCode(roleId));
  if (user.isDemoUser) {
    roleCodes = ['demo'];
  }
  store.dispatch('permission/setRoles', roleCodes);
  const accessRoutes = await store.dispatch(
    'permission/generateRoutes',
    roleCodes
  );
  const userHasLiChenAuth = await chackUserHasProjectAuth(user, 'project_4');
  //过滤掉历城设备分布图菜单  限制历城用户、历城管理员（id=18）、超管（id=1）可看
  if (!userHasLiChenAuth && ![1, 18].includes(user.id)) {
    accessRoutes[0].children = accessRoutes[0].children.filter((route: any) => {
      return (
        route.name != 'deviceProfile' && route.name != 'deviceProfileDetail'
      );
    });
  }
  // 过滤崔家店事件统计菜单
  const userHasCjdAuth = await chackUserHasProjectAuth(user, 'project_1');
  if (!userHasCjdAuth) {
    accessRoutes[0].children = accessRoutes[0].children.filter((route: any) => {
      return route.name != 'cjStore-eventStatistics';
    });
  }
  // 过滤富池历史数据菜单
  const userHasFcAuth = await chackUserHasProjectAuth(user, 'project_6');
  if (!userHasFcAuth) {
    accessRoutes[0].children = accessRoutes[0].children.filter((route: any) => {
      return route.name != 'fcHistory';
    });
  }
  resetRouter();
  router.addRoutes(accessRoutes);
  store.dispatch('menu/generateMenus', accessRoutes[0]?.children ?? []);
}

async function chackUserHasProjectAuth(
  user: UserRoleAware & { id: number },
  key: string
) {
  if (user.isRole(RoleId.User)) {
    const res = await new GetSceneTreeRequest({
      isAdmin: !getApiAccess().localUser!.isRole(RoleId.User),
      hasCollectorNode: true,
    }).perform();
    const node = findNode((node: ISceneTreeNode) => node.key === key, res.data);
    return node?.id ? true : false;
  } else {
    return false;
  }
}
