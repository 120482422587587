export interface TreeNode<T> {
  children?: T[];
}

export function findNode<T extends TreeNode<T>>(
  equal: (node: T) => boolean,
  nodes: T[]
) {
  const found = findNodes(equal, nodes, true);
  return found.length ? found[0] : null;
}

export function findNodes<T extends TreeNode<T>>(
  equal: (node: T) => boolean,
  nodes: T[],
  findFirst = false
) {
  const found: T[] = [];
  for (const node of nodes ?? []) {
    if (equal(node)) {
      found.push(node);
      if (findFirst) {
        break;
      }
    }
    if (node.children) {
      found.push(...findNodes(equal, node.children));
    }
  }
  return found;
}
