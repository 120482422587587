import { formatTime } from '@/utils/time';

// eslint-disable-next-line no-undef
const version = __APP_VERSION__; // 引用从webpack创建的变量

version.commitDateTime = formatTime(new Date(version.commitDateTime));
version.buildTime = formatTime(new Date(version.buildTime));
Object.freeze(version);

window['__APP_VERSION__'] = version;

logVersionInfo(version);

function logVersionInfo(version) {
  console.info(
    '%c=== 构建版本 ===\n分支名称: %s\n最后提交编号: %s\n最后提交时间: %s\n构建时间: %s',
    'color:deeppink',
    version.branch,
    version.commitHash,
    version.commitDateTime,
    version.buildTime
  );
}
