import EntityId from '@/domain/entityId';

export default class ModbusChannelId implements EntityId {
  private _dbId: number;
  get dbId() {
    return this._dbId;
  }

  constructor({ dbId }: Partial<{ dbId: number }>) {
    this._dbId = dbId!;
  }

  equals(other: any) {
    if (!(other instanceof ModbusChannelId)) {
      return false;
    }
    if (typeof this.dbId == 'number' && typeof other.dbId == 'number') {
      return this.dbId == other.dbId;
    }
    return false;
  }

  valueOf() {
    if (this._dbId) return this._dbId;
  }

  toString() {
    if (this._dbId) return this._dbId + '';
    return '';
  }
}
