import APIUser from '@/online/api/requests/responses/apiUser';

import { StoreOptions } from 'vuex';

export default {
  namespaced: true,
  state: {
    user: null,
  },
  actions: {
    save({ state }: any, user: APIUser) {
      state.user = user;
    },
  },
} as StoreOptions<{
  user: APIUser | null;
}>;
