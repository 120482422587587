import { AxiosError, ResponseType } from 'axios';
import signals from 'signals';
import http from './http';

export default abstract class WebRequest {
  method = HttpMethod.Get;
  url: string;
  queryParams: Record<string, any> = {};
  formParams: Record<string, any> = {};
  headers: Record<string, string | number | boolean> = {};
  rawContent: any = null;
  contentType: string;
  responseType?: ResponseType;
  timeout: number;

  failed = new signals.Signal() as signals.Signal<AxiosError>;
  finished = new signals.Signal();

  constructor(url: string) {
    this.url = url;
  }

  addHeader(name: string, value: string) {
    this.headers[name] = value;
  }

  addParam(name: string, value: any, paramType: ParamType = ParamType.Query) {
    switch (paramType) {
      case ParamType.Query:
        if (typeof value != 'undefined') {
          if (value instanceof Date) {
            value = value.toISOString();
          } else {
            value = value.toString();
          }
          this.queryParams[name] = value;
        }
        break;
      case ParamType.Form:
        this.formParams[name] = value;
        break;
    }
  }

  addRaw(raw: any) {
    this.rawContent = raw;
  }

  perform<T = any>() {
    let data;
    if (this.method === HttpMethod.Post) {
      data = this.rawContent;
    }

    if (this.contentType) {
      this.addHeader('Content-Type', this.contentType);
    }

    return http
      .request<any, T>({
        method: this.method,
        url: this.url,
        headers: this.headers,
        params: this.queryParams,
        responseType: this.responseType,
        timeout: this.timeout,
        data,
      })
      .then((res) => {
        this.finished.dispatch();
        return res;
      })
      .catch((e) => {
        this.failed.dispatch(e);
        return null;
      });
  }
}

export enum ParamType {
  Query,
  Form,
}

export enum HttpMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Delete = 'delete',
}
