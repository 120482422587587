
import Vue from 'vue';

export default Vue.extend({
  name: 'TimeDate',
  components: {},
  data() {
    const now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    };
  },
});
