
import { MenuItem } from '@/store/menu';
import Vue from 'vue';

export default Vue.extend({
  props: {
    activeMenu: { type: String },
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      lastActiveMenuIndex: 0,
    };
  },
  watch: {
    activeMenu: {
      handler(val) {
        this.updateActive(val);
      },
      immediate: true,
    },
  },
  methods: {
    onMenuItemClick(item: MenuItem) {
      if (item.children && item.children.length) {
        item = item.children[0];
      } else {
        this.updateActive(item.path);
      }
      this.$router.push(item.path);
    },
    updateActive(newActiveMenu: string) {
      const index = this.menuList.findIndex(
        (it: any) => it.path == newActiveMenu
      );
      this.lastActiveMenuIndex = index;
    },
  },
});
