
import Vue from 'vue';
import MenuOption from './menu.vue';
import TimeDate from './timeDate.vue';
import { mapState } from 'vuex';
import LoginUser from './LoginUser/index.vue';
import { appTitle } from '@/appInfo';

export default Vue.extend({
  name: 'Navigation',
  components: { MenuOption, LoginUser, TimeDate },
  data() {
    return {
      activeSidebar: false,
      appTitle,
      layoutEl: null as HTMLElement | null,
      enableBlurFilter: true,
    };
  },
  computed: {
    ...mapState({
      activeMenu: (state: any) => state.menu.activeMenu,
      menus: (state: any) => state.menu.menus,
    }),
  },
  mounted() {
    this.layoutEl = document.querySelector('#home-layout')!;
    this.layoutEl.addEventListener('scroll', this.onScroll);
  },
  destroyed() {
    this.layoutEl!.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      if (this.layoutEl!.scrollTop > 20) {
        this.$el.classList.add('overlay');
      } else {
        this.$el.classList.remove('overlay');
      }
    },
  },
});
