import * as treeUtil from '@/utils/tree';
import { Route, RouteConfig } from 'vue-router';
import { TreeNode } from '@/utils/tree';
import { StoreOptions } from 'vuex';

export default {
  namespaced: true,
  state: {
    menus: [],
    activeMenu: '',
  },
  getters: {},
  mutations: {},
  actions: {
    generateMenus({ state }, routes: RouteConfig[]) {
      function convert(routes: RouteConfig[]): MenuItem[] {
        return routes
          .filter((route) => !route.meta?.hidden)
          .map((route: RouteConfig) => {
            return {
              name: route.meta?.title,
              path: route.path,
              children: convert(route.children ?? []),
            } as MenuItem;
          });
      }
      state.menus = convert(routes);
    },
    change({ state }, route: Route) {
      const findFn = (item: MenuItem) => {
        if (item.path == route.path) {
          return true;
        }
        if (
          item.children &&
          treeUtil.findNode<MenuItem>(
            (item) => item.path == route.path,
            item.children
          )
        ) {
          return true;
        }
        return false;
      };
      const item = treeUtil.findNode<MenuItem>(
        findFn,
        state.menus as MenuItem[]
      );
      state.activeMenu = item?.path ?? '';
    },
  },
} as StoreOptions<{
  activeMenu: string;
  menus: MenuItem[];
}>;

export interface MenuItem extends TreeNode<MenuItem> {
  path: string;
  name: string;
}
