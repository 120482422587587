import { RouteConfig } from 'vue-router';

import ParentLayout from '@/layouts/ParentLayout.vue';
import HomeLayout from '@/layouts/main/HomeLayout.vue';

export const constantRoutes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login/index.vue'),
    meta: {
      title: '欢迎登录',
    },
  },
];

export const asyncRoutes: RouteConfig[] = [
  {
    path: '/',
    component: HomeLayout,
    children: [
      {
        path: '/device-layout',
        name: 'device-layout',
        component: () => import('@/pages/device_layout/index.vue'),
        meta: {
          hidden: true,
          title: '设备分布',
        },
      },

      {
        path: '/point-data',
        name: 'pointData',
        component: () => import('@/pages/point-data/index.vue'),
        meta: {
          hidden: true,
          title: '测点数据',
        },
      },
      {
        path: '/uhome',
        name: 'uhome',
        component: () => import('@/pages/uhome/index.vue'),
        meta: {
          hidden: true,
          title: '监测对象',
          roles: ['user'],
        },
      },
      {
        path: '/data-preview',
        name: 'dataPreview',
        component: () => import('@/pages/data-preview/index.vue'),
        meta: {
          hidden: true,
          title: '数据预览',
        },
      },
      {
        path: '/scoring',
        name: 'scoring',
        component: () => import('@/pages/scoring/index.vue'),
        meta: {
          title: '故障预警',
          roles: ['user', 'demo'],
        },
      },
      {
        path: '/monitor',
        name: 'monitor',
        component: () => import('@/pages/monitor/index.vue'),
        meta: {
          title: '实时监测',
          roles: ['user', 'demo'],
        },
      },
      {
        path: '/history',
        name: 'history',
        component: () => import('@/pages/history/index.vue'),
        meta: {
          title: '历史数据',
          roles: ['user', 'demo'],
        },
      },
      {
        path: '/fc-history',
        name: 'fcHistory',
        component: () => import('@/pages/fc-history/index.vue'),
        meta: {
          title: '富池历史数据',
          roles: ['user', 'demo'],
        },
      },
      // {
      //   path: '/event-stats',
      //   name: 'eventStatistics',
      //   component: () => import('@/pages/event-statistics/index.vue'),
      //   meta: {
      //     title: '事件统计',
      //     roles: ['user'],
      //   },
      // },
      {
        path: '/cj-event-stats',
        name: 'cjStore-eventStatistics',
        component: () => import('@/pages/cj-event-statistics/index.vue'),
        meta: {
          title: '崔家店事件统计',
          roles: ['user'],
        },
      },
      {
        path: '/event-stats/details',
        name: 'eventDetail',
        component: () =>
          import('@/pages/event-statistics/EventDetail/index.vue'),
        meta: {
          hidden: true,
          title: '事件详情',
          roles: ['user'],
        },
      },
      {
        path: '/huawei',
        component: () => import('@/pages/huawei/index.vue'),
        meta: {
          title: '华为',
          roles: ['user'],
          hidden: true,
        },
      },
      {
        path: '/huawei/playback',
        component: () => import('@/pages/huawei/playback/index.vue'),
        meta: {
          title: '华为数据回放',
          roles: ['user'],
          hidden: true,
        },
      },
      {
        path: '/project',
        name: 'project',
        component: () => import('@/pages/admin/project/index.vue'),
        meta: {
          title: '项目管理',
          roles: ['superAdmin'],
        },
      },
      {
        path: '/scenes',
        name: 'scenes',
        component: () => import('@/pages/admin/scenes/index.vue'),
        meta: {
          title: '场景管理',
          roles: ['superAdmin', 'manager'],
        },
      },
      {
        path: '/device-profile',
        name: 'deviceProfile',
        component: () => import('@/pages/admin/device-profile/index.vue'),
        meta: {
          title: '历城站测点分布图',
          roles: ['superAdmin', 'manager', 'user'],
        },
      },
      {
        path: '/sensor-point',
        name: 'SensorPoint',
        component: () => import('@/pages/admin/sensor-point/index.vue'),
        meta: {
          title: '测点管理',
          roles: ['superAdmin', 'manager'],
        },
      },
      {
        path: '/device-profile-detail',
        name: 'deviceProfileDetail',
        component: () => import('@/pages/admin/device-profile/detail.vue'),
        meta: {
          title: '测点分布图详情',
          roles: ['superAdmin', 'manager', 'user'],
          hidden: true,
        },
      },
      {
        path: '/collector',
        name: 'collector',
        component: () => import('@/pages/admin/collector/index.vue'),
        meta: {
          title: '采集器管理',
          roles: ['superAdmin', 'manager'],
        },
      },
      {
        path: '/sensor-manager',
        name: 'Sensor',
        component: ParentLayout,
        meta: {
          title: '传感器管理',
          roles: ['superAdmin', 'manager'],
        },
        children: [
          {
            path: '/sensor-manager/sensor',
            name: 'sensor',
            component: () => import('@/pages/sensor/index.vue'),
            meta: {
              title: '传感器列表',
              roles: ['superAdmin', 'manager'],
            },
          },
          {
            path: '/sensor-manager/sensor-type',
            name: 'SensorType',
            component: () => import('@/pages/sensor-type/index.vue'),
            meta: {
              title: '传感器类型',
              roles: ['superAdmin', 'manager'],
            },
          },
        ],
      },
      {
        path: '/log',
        name: 'log',
        component: () => import('@/pages/log/index.vue'),
        meta: {
          title: '日志管理',
          roles: ['superAdmin'],
        },
      },
      {
        path: '/system',
        name: 'System',
        component: ParentLayout,
        meta: {
          title: '系统管理',
          roles: ['superAdmin', 'manager'],
        },
        children: [
          {
            path: '/system/user',
            name: 'user',
            component: () => import('@/pages/user/index.vue'),
            meta: {
              title: '用户管理',
            },
          },
          {
            path: '/system/role',
            name: 'role',
            component: () => import('@/pages/role/index.vue'),
            meta: {
              title: '角色管理',
              hidden: true,
              roles: ['superAdmin'],
            },
          },
          {
            path: '/system/about',
            name: 'user',
            component: () => import('@/pages/about/index.vue'),
            meta: {
              title: '关于系统',
              roles: ['superAdmin', 'manager'],
            },
          },
        ],
      },

      {
        path: '*',
        component: () => import('@/pages/404.vue'),
        meta: {
          hidden: true,
        },
      },
    ],
    meta: {
      roles: '*',
    },
  },
];
