/** 场景树结点类型  */
export enum SceneTreeNode {
  /** 项目 */
  Project = 5,
  /** 场景 */
  Scene = 1,
  /** 工点 */
  Workpoint = 2,
  /** 采集器 */
  PtCollector = 3,
  /** 通道 */
  PtChannel = 4,
  /** 传感器 */
  Sensor = 4,
  /** ModBus */
  ModbusDevice = 6,
  /** ModBus通道 */
  ModbusChannel = 7,
}
