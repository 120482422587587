import Vue from 'vue';
import Vuex from 'vuex';
import menu from './menu';
import user from './user';
import permission from './permission';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    menu,
    user,
    permission,
  } as any,
});
